/** Created by Tomáš Korec on 2020-1-12 */

import React from "react"
import { createUseStyles } from "react-jss"
import PropTypes from "prop-types"

import SearchBar from "./SearchBar"
import AccountBar from "./AccountBar"
import Icon from "../common/Icon"

const useStyles = createUseStyles(theme => ({
  header: {
    boxSizing: "border-box",
    border: 0,
    display: "flex",
    flexShrink: 0,
    flexDirection: "column",
    width: "100%",
    minWidth: "24rem",
    height: "3.5rem",
    color: theme.color.grey600,
    backgroundColor: theme.color.theme500,
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    zIndex: 100,
  },
  headerBar: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  logo: ({ menuCollapsed }) => ({
    position: "absolute",
    width: "12rem",
    padding: "0.85rem 1rem 0.65rem 1rem",
    transform: menuCollapsed ? "translate(-12rem)" : "translate(0)",
    transition: "transform 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    "& img": {
      height: "2rem",
      maxWidth: "10rem",
    },
    "@media screen and (max-width: 40rem)": {
      display: "none",
    },
  }),
  logoBox: ({ menuCollapsed }) => ({
    flexShrink: 0,
    width: menuCollapsed ? 0 : "12rem",
    transition: "width 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    "@media screen and (max-width: 40rem)": {
      display: "none",
    },
  }),
  menuToggle: {
    flexShrink: 0,
    width: "4.5rem",
    fontSize: "1.25rem",
    lineHeight: "1.25rem",
    textAlign: "center",
    "& span": {
      color: theme.color.black,
      cursor: "pointer",
    },
  },
  searchBar: {
    flexGrow: 1,
    padding: "0 1rem 0 1rem",
    "& div": {
      maxWidth: "500px",
      minWidth: "150px",
      width: "60%",
      "@media screen and (max-width: 40rem)": {
        width: "100%",
      },
    },
  },
  accountBar: {
    fontSize: "1rem",
  },
}))

const HeaderBar = ({ searchValue, menuCollapsed, toggleMenu }) => {
  const classes = useStyles({ menuCollapsed })
  return (
    <header className={classes.header}>
      <div className={classes.headerBar}>
        <div className={classes.logo}>
          <img alt="IPEX a.s." src={`/static/ipex-logo-grey-small.png`} />
        </div>
        <div className={classes.logoBox} />
        <div className={classes.menuToggle}>
          <span onClick={toggleMenu}>
            <Icon iconType={menuCollapsed ? "bars" : "chevron-left"} size={27} />
          </span>
        </div>
        <div className={classes.searchBar}>
          <SearchBar searchValue={searchValue} />
        </div>
        <div className={classes.accountBar}>
          <AccountBar />
        </div>
      </div>
    </header>
  )
}

HeaderBar.propTypes = {
  searchValue: PropTypes.string,
  menuCollapsed: PropTypes.bool,
  toggleMenu: PropTypes.func,
}

export default HeaderBar
