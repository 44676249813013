import { parse } from "qs"
import { isBrowser } from "../core"

// if we have client-side query we use it, otherwise, we use the server-side value
const getQuery = (serverSideQuery = {}) => {
  if (isBrowser()) return parse(window.location.search, { ignoreQueryPrefix: true })
  return serverSideQuery
}

export default getQuery
