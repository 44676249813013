/** Created by Tomáš Korec on 2020-1-12 */

import React, { useContext } from "react"
import { createUseStyles } from "react-jss"
import PropTypes from "prop-types"

import { CookieContext, hasRole, role } from "../../core"
import { useI18n } from "../../internationalization/i18n"

import MenuLink from "./MenuLink"

const useStyles = createUseStyles(theme => ({
  sideBar: {
    flex: "0 0 auto",
  },
  menu: {
    display: "flex",
    flexDirection: "column",
    flex: "1 0 auto",
    position: "relative",
    overflowX: "hidden",
    overflowY: "auto",
    zIndex: 99,
    left: 0,
    right: "auto",
    top: 0,
    outline: 0,
    width: ({ collapsed }) => (collapsed ? "4.5rem" : "17.5rem"),
    height: "100vh",
    paddingTop: "4rem",
    color: theme.color.grey50,
    backgroundColor: ({ isOperator }) => (isOperator ? theme.color.winy : theme.color.grey600),
    transition: "width 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    "@media screen and (max-width: 40rem)": {
      width: ({ collapsed }) => (collapsed ? "0rem" : "17.5rem"),
      position: "absolute",
    },
  },
  list: {
    margin: 0,
    padding: "0.25rem 0",
    position: "relative",
    listStyle: "none",
  },
}))

const SideBar = ({ collapsed }) => {
  const { user } = useContext(CookieContext)
  const classes = useStyles({ collapsed, isOperator: user && user.operator })
  const messages = useI18n().messages.common

  return (
    <div className={classes.sideBar}>
      <div className={classes.menu}>
        <nav>
          <ul className={classes.list}>
            <div>
              {!user ? (
                <NoRoleMenu msg={messages.noRoleMenu} />
              ) : user.operator && !user.hasMask ? (
                <OperatorMenu msg={messages.operatorMenu} />
              ) : (
                <ProviderMenu
                  settings={hasRole(user.roles, [role.SUPERADMIN, role.PORTATION])}
                  statistics={hasRole(user.roles, [role.SUPERADMIN, role.ADMIN, role.BILLING])}
                  msg={messages.providerMenu}
                />
              )}
            </div>
          </ul>
        </nav>
      </div>
    </div>
  )
}

const NoRoleMenu = ({ msg }) => (
  <MenuLink to="/support" id="support" icon="message-circle" label={msg.support} />
)

NoRoleMenu.propTypes = {
  msg: PropTypes.object,
}

const OperatorMenu = ({ msg }) => (
  <>
    <MenuLink to="/providers" id="providers" icon="cubes" label={msg.providers} />
    <MenuLink to="/simcards" id="simcards" icon="simcard" label={msg.simcards} />
    <MenuLink
      to="/zoomReportOperator"
      id="zoomReportOperator"
      icon="credit-card"
      label={msg.zoomReport}
    />
    <MenuLink
      to="/connectorMigration"
      id="connectorMigration"
      icon="database"
      label={msg.connectorMigration}
    />
    <MenuLink to="/numbers" id="numbers" icon="list-numbered" label={msg.numbers} />
    <MenuLink to="/isp-pricelists" id="ispPricelists" icon="tag" label={msg.ispPricelists} />
  </>
)

OperatorMenu.propTypes = {
  msg: PropTypes.object,
}

const ProviderMenu = ({ statistics, settings, msg }) => (
  <>
    <MenuLink to="/dashboard" id="dashboard" icon="home" label={msg.dashboard} />
    <MenuLink to="/customers" id="customers" icon="user" label={msg.customers} />
    <MenuLink to="/services?type=all" id="services" icon="grid" label={msg.services} />
    <MenuLink to="/requests" id="requests" icon="shopping-cart" label={msg.requests} />
    <MenuLink to="/invoices" id="invoices" icon="credit-card" label={msg.invoices} />
    <MenuLink to="/wholesaleInvoices" id="wholesale-invoices" icon="money" label={msg.bills} />
    {statistics && (
      <MenuLink to="/statistics" id="statistics" icon="pie-chart" label={msg.statistics} />
    )}
    {settings && (
      <MenuLink
        to="/settingsDashboard"
        id="settingsDashboard"
        icon="settings"
        label={msg.providerSettings}
      />
    )}
    <MenuLink to="/support" id="support" icon="message-circle" label={msg.support} />
    <MenuLink to="/ticketing" id="ticketing" icon="ticket" label={msg.ticketing} />
  </>
)

ProviderMenu.propTypes = {
  statistics: PropTypes.bool,
  settings: PropTypes.bool,
  msg: PropTypes.object,
}

SideBar.propTypes = {
  collapsed: PropTypes.bool,
}

export default SideBar
