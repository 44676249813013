/** Created by Tomáš Korec on 2020-1-12 */

import React, { useContext } from "react"
import PropTypes from "prop-types"
import { createUseStyles } from "react-jss"
import NextLink from "next/link"

import { CookieContext, isBrowser } from "../../core"
import getPageSection from "../../utils/pageUtils"
import Icon from "../common/Icon"

const useStyles = createUseStyles(theme => ({
  item: ({ isActive, isOperator }) => ({
    position: "relative",
    boxSizing: "border-box",
    border: 0,
    outline: 0,
    display: "flex",
    width: "100%",
    height: "3.5rem",
    alignItems: "center",
    justifyContent: "flex-start",
    fontSize: "1rem",
    color: isOperator ? theme.color.pink : theme.color.grey200,
    backgroundColor: isActive && (isOperator ? theme.color.darkWiny : theme.color.darkGrey),
    cursor: "pointer",
    whiteSpace: "nowrap",
    textDecoration: "none",
    transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      color: theme.color.white,
    },
  }),
  icon: {
    display: "inline-flex",
    fontSize: "1.4rem",
    width: "4.5rem",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
  },
  text: {
    flex: "1 1 auto",
  },
}))

const MenuLink = ({ id, icon, label, to }) => {
  const { user } = useContext(CookieContext)
  const section = getPageSection()
  const classes = useStyles({
    isActive: isBrowser() && section === id,
    isOperator: user && user.operator,
  })
  return (
    <NextLink href={to}>
      <a type="button" title={label}>
        <div className={classes.item} tabIndex="0">
          <div className={classes.icon} data-cy={`menu-${id}`}>
            <Icon iconType={icon} size={24} />
          </div>

          <div className={classes.text}>
            <span>{label}</span>
          </div>
        </div>
      </a>
    </NextLink>
  )
}

MenuLink.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  to: PropTypes.string,
}

export default MenuLink
