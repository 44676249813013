import React from "react"
import { ApolloProvider } from "react-apollo"
import App, { Container } from "next/app"
import Router from "next/router"
import moment from "moment"
import { themes, ThemeProvider } from "@ipex/storybook"
import "../public/main.css"

import {
  isAuthenticated,
  isServer,
  loginRedirect,
  CookieContext,
  getCookies,
  hasSomeRole,
  isB2BExported,
} from "../core"
import { LanguageProvider, getLanguageFromQuery } from "../internationalization/i18n"
import { AlertsProvider } from "../core/alerts/AlertsManager"
import { ModalProvider } from "../core/modal"

import Layout from "./_layout"

import client from "../utils/apollo"
import getQuery from "../utils/getQuery"

moment.locale("cs")

export default class b2bApp extends App {
  static async getInitialProps({ Component, ctx, query }) {
    const { req } = ctx
    let isStatic = false
    let pageProps = {}
    if (isServer() && !req.headers) {
      // Inside static export
      isStatic = true
    }
    const cookies = getCookies(ctx)
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
    }
    return { language: getLanguageFromQuery(query) || cookies.lang, cookies, pageProps, isStatic }
  }

  componentDidMount() {
    // This hack is used to remove trailing slashes when statically exporting the app.
    // One weird thing is that when hovering over a link, it still looks like trailing slashes are there
    // But whenever you actually click the link, they.re gone. So I guess this does indeed solve it?
    // eslint-disable-next-line no-undef
    __NEXT_DATA__.nextExport = false

    // const style = document.getElementById("server-side-styles")
    //
    // if (style) {
    //   style.parentNode.removeChild(style)
    // }

    const { router, cookies } = this.props
    const isAuth = isAuthenticated(cookies)
    if (!isAuth && router.pathname !== "/login") {
      loginRedirect()
    } else if (
      isAuth &&
      !hasSomeRole(cookies.user) &&
      router.pathname !== "/support" &&
      router.pathname !== "/calculator-helpdesk" &&
      !isB2BExported()
    ) {
      Router.push({ pathname: "/support" })
    }
  }

  componentDidCatch(error, errorInfo) {
    super.componentDidCatch(error, errorInfo)
  }

  getThemeFromQuery = query => query && query["theme"]

  render() {
    const { Component, pageProps, router, cookies, language } = this.props
    const query = getQuery(router.query)
    const initialLanguage = getLanguageFromQuery(query) || language
    const themeFromQuery = this.getThemeFromQuery(query)
    const initialTheme =
      themeFromQuery && themes[themeFromQuery] ? themes[themeFromQuery] : themes.ipex
    const isAuth = isAuthenticated(cookies)
    if (!isAuth && router.pathname !== "/login") {
      return null
    }

    if (
      isAuth &&
      !hasSomeRole(cookies.user) &&
      router.pathname !== "/support" &&
      router.pathname !== "/calculator-helpdesks" &&
      !isB2BExported()
    ) {
      return null
    }

    const content =
      router.pathname === "/login" ? (
        <Component {...pageProps} query={query} url={router} />
      ) : (
        <Layout url={router.url} query={query} mainClassName={Component.mainClassName}>
          <Component
            {...pageProps}
            query={query}
            cookies={cookies}
            url={router}
            rerender={this.forceUpdate.bind(this)}
          />
        </Layout>
      )

    return (
      <LanguageProvider initialLanguage={initialLanguage}>
        <ThemeProvider theme={initialTheme}>
          <AlertsProvider>
            <CookieContext.Provider value={cookies}>
              <Container>
                <ApolloProvider client={client}>
                  <ModalProvider>{content}</ModalProvider>
                </ApolloProvider>
              </Container>
            </CookieContext.Provider>
          </AlertsProvider>
        </ThemeProvider>
      </LanguageProvider>
    )
  }
}
