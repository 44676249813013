import { useRouter } from "next/router"
import sites from "../pages/pages.json"

function getPageSection() {
  const router = useRouter()
  const url = router.pathname.split(/[/,?]/)[1]
  const page = sites[url]
  if (page) {
    const sectionName = page.section
    return sectionName
  }
}

export default getPageSection
