import React from "react"
import { createUseStyles } from "react-jss"
import PropTypes from "prop-types"
import NextLink from "next/link"

import Icon from "../common/Icon"

const useStyles = createUseStyles({
  iconLink: {
    cursor: "pointer",
  },
})

const IconLink = ({ id, to, onClick, icon, title, children, ...props }) => {
  const classes = useStyles()

  const href = to && to.startsWith("/") ? to : "/" + to
  return (
    <span className={classes.iconLink}>
      <NextLink href={href} {...props}>
        <Icon iconType={icon} onClick={onClick} title={title} id={id} data-cy={id} {...props}>
          {children}
        </Icon>
      </NextLink>
    </span>
  )
}

IconLink.propTypes = {
  id: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
}

export default IconLink
