import React, { useRef } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { createUseStyles } from "react-jss"

import Icon from "../../components/common/Icon"
import { useI18n } from "../../internationalization/i18n"
import { useClick } from "../../utils/hooks"

const useModalStyles = createUseStyles(theme => ({
  overlay: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 999999,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.65)",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.color.white,
    minWidth: 450,
    borderRadius: "4px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem 1rem",
    borderBottom: `1px solid ${theme.color.grey200}`,

    "& > .modal-header": {
      fontSize: "1rem",
    },

    "& > .close-icon": {
      padding: "0.25rem",
      cursor: "pointer",

      "&:hover": {
        color: theme.color.theme500,
      },
    },
  },
  content: {
    padding: "1rem",
    fontSize: "0.8125rem",
    overflowX: "auto",
    overflowY: "visible",
  },
}))

const MODAL_ROOT = "modal-root"

const Modal = ({ content, title, isCloseDisabled, onClose = () => {} }) => {
  const classes = useModalStyles()
  const overlayRef = useRef()
  const messages = useI18n().messages.common

  useClick(overlayRef, onClose)

  return ReactDOM.createPortal(
    <div ref={overlayRef} className={classes.overlay}>
      <div className={classes.container}>
        <div className={classes.header}>
          <h1 className="modal-header">{title}</h1>
          {!isCloseDisabled && (
            <span className="close-icon" title={messages.buttons.close} onClick={onClose}>
              <Icon iconType="x" />
            </span>
          )}
        </div>
        <div className={classes.content}>{content}</div>
      </div>
    </div>,
    document.getElementById(MODAL_ROOT),
  )
}

Modal.propTypes = {
  content: PropTypes.node,
  title: PropTypes.string,
  onClose: PropTypes.func,
}

export { Modal }
