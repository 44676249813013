/** Created by Tomáš Korec on 2020-1-12 */

import React, { useContext, useState, useEffect } from "react"
import { createUseStyles } from "react-jss"
import PropTypes from "prop-types"
import Router from "next/router"

import { CookieContext, homeRedirect } from "../../core"
import { useI18n } from "../../internationalization/i18n"

import Icon from "../common/Icon"

const useStyles = createUseStyles(theme => ({
  textFilter: {
    position: "relative",
    display: "flex",
  },
  textFilterInput: {
    flex: 1,
    border: 0,
    width: "100%",
    borderRadius: "0.125rem 0 0 0.125rem",
    borderRightStyle: "none",
    fontSize: "0.875rem",
    outline: "none",
    padding: "0.5rem 1rem 0.5rem 2rem",
    "&::placeholder": {
      color: theme.color.grey50,
    },
    backgroundColor: ({ value }) => (value ? theme.color.white : theme.color.theme650),
    color: ({ value }) => (value ? theme.color.black : theme.color.white),
  },
  icon: {
    position: "absolute",
    fontSize: "0.8rem",
    top: "0.6em",
    left: "0.7em",
    color: theme.color.black,
  },
  clearAddon: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: "0.5rem",
    border: 0,
    outline: 0,
    cursor: ({ value }) => value && "pointer",
    "&:hover": {
      color: theme.color.theme500,
    },
    "& svg": {
      visibility: ({ value }) => (value ? "visible" : "hidden"),
    },
  },
}))

const callSearch = (searchValue, user) => {
  if (!user) {
    Router.push({ pathname: "/login" })
  } else if (searchValue) {
    user.operator && !user.hasMask
      ? Router.push({ pathname: "/fulltextProviders", query: { searchValue: searchValue } })
      : Router.push({
          pathname: "/fulltext",
          query: { searchValue: searchValue.toString().toLowerCase() },
        })
  } else {
    user.operator && !user.hasMask
      ? Router.push({ pathname: "/providers" })
      : Router.push({ pathname: "/dashboard" })
  }
}

const SearchBar = ({ searchValue }) => {
  const { user } = useContext(CookieContext)
  const [value, setValue] = useState(searchValue)
  const [keystrokeTimeout, setKeystrokeTimeout] = useState(null)
  const messages = useI18n().messages.common

  useEffect(() => {
    setValue(searchValue || "")
  }, [searchValue])

  const handleInputChange = e => {
    const value = e.target.value
    setValue(value)
    clearTimeout(keystrokeTimeout)

    const timeout = setTimeout(() => {
      value !== "" && callSearch(value, user)
    }, 800)
    setKeystrokeTimeout(timeout)
  }

  const clear = () => {
    setValue("")
    Router.asPath && Router.asPath.includes("fulltext") && homeRedirect(user)
  }

  const classes = useStyles({ value })

  return (
    <div className={classes.textFilter}>
      <label htmlFor="fulltext-search-input" className={classes.icon}>
        <Icon iconType="search" />
      </label>
      <input
        id="fulltext-search-input"
        name="fulltext-search-input"
        className={classes.textFilterInput}
        type="text"
        value={value}
        onChange={handleInputChange}
        data-cy="fulltext-search-input"
        placeholder={messages.search}
      />
      <span className={classes.clearAddon} onClick={clear} data-cy="fulltext-search-clear">
        <Icon iconType="x" />
      </span>
    </div>
  )
}

SearchBar.propTypes = {
  searchValue: PropTypes.string,
}

export default SearchBar
