import React, { useState, useContext } from "react"
import PropTypes from "prop-types"

import { Modal } from "./Modal"

const ModalContext = React.createContext()

const DEFAULT_MODAL_STATE = {
  title: "",
  content: null,
  isCloseDisabled: false,
}

const ModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState(DEFAULT_MODAL_STATE)

  const handleShowModal = ({ title = "", content = null, isCloseDisabled = false }) =>
    setModalState({
      title,
      content,
      isCloseDisabled,
    })

  const handleCloseModal = () => setModalState(DEFAULT_MODAL_STATE)

  const operations = {
    showModal: handleShowModal,
    closeModal: handleCloseModal,
  }

  return (
    <ModalContext.Provider value={operations}>
      {modalState.content !== null && (
        <Modal
          content={modalState.content}
          title={modalState.title}
          isCloseDisabled={modalState.isCloseDisabled}
          onClose={handleCloseModal}
        />
      )}
      {children}
    </ModalContext.Provider>
  )
}

ModalProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

const useModal = () => {
  const context = useContext(ModalContext)

  if (!context) {
    throw new Error("[useModal] useModal must be used within ModalProvider")
  }

  return context
}

export { useModal, ModalProvider }
