import React, { useContext } from "react"
import * as PropTypes from "prop-types"
import { createUseStyles } from "react-jss"

import { AlertsContext } from "../../core/alerts/AlertsManager"
import Alert from "../common/Alert"

const useStyles = createUseStyles({
  pageBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: "100",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0, 0.5)",
    paddingLeft: ({ sidePanel }) => (sidePanel ? "4.5rem" : 0),
    "@media screen and (max-width: 40rem)": {
      padding: () => 0,
    },
  },
  alertList: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "40rem",
    padding: "1rem",
    "& > div": {
      width: "100%",
    },
  },
})

const AlertList = ({ sidePanel }) => {
  const { alerts, removeAlert, clearAlerts } = useContext(AlertsContext)

  const classes = useStyles({ sidePanel })

  if (!alerts.length) return null

  return (
    <div
      className={classes.pageBackground}
      onClick={e => {
        const targetClass = e.target.className.toString()
        if (targetClass.includes("pageBackground") || targetClass.includes("alertList")) {
          clearAlerts()
        }
      }}
    >
      <div className={classes.alertList}>
        {alerts.map(alert => (
          <Alert {...alert} key={alert.id} onClose={() => removeAlert(alert.id)} />
        ))}
      </div>
    </div>
  )
}

AlertList.propTypes = {
  sidePanel: PropTypes.bool,
}

export default AlertList
