/** Created by Tomáš Korec on 2020-1-12 */

import React, { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import PropTypes from "prop-types"
import Router from "next/dist/client/router"

import {
  CookieContext,
  isAuthenticated,
  hasSomeRole,
  resetAuth,
  loginRedirect,
  homeRedirect,
  setAutoLogout,
} from "../core"

import HeaderBar from "../components/_layout/HeaderBar"
import SideBar from "../components/_layout/SideBar"

import { useB2BExported } from "../utils/hooks"
import AlertList from "../components/_layout/AlertList"
import { useAlerts } from "../core/alerts/AlertsManager"
import { useI18n } from "../internationalization/i18n"

// noinspection JSUnusedLocalSymbols
const useStyles = createUseStyles({
  page: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    minHeight: "100%",
    display: "flex",
    minWidth: "24rem",
    fontSize: "0.8125rem",
    "& main": {
      width: ({ collapsed, isExported }) =>
        isExported ? "100%" : collapsed ? "calc(100% - 4.5rem)" : "calc(100% - 16rem)",
      "@media screen and (max-width: 40rem)": {
        width: ({ collapsed }) => "100%",
      },
    },
  },
})

const Layout = ({ children, query }) => {
  const { alertWarning, clearAlerts } = useAlerts("Auth")
  const msg = useI18n().messages.common.warning
  const { auth, user, provider } = useContext(CookieContext)
  const [collapsed, setCollapsed] = useState(true)
  const isExported = useB2BExported()
  const classes = useStyles({ collapsed, isExported })

  const isAuth = isAuthenticated({ auth })
  const justSupport = isAuth && !hasSomeRole(user)

  useEffect(() => {
    if (!isExported && auth && auth.expire) {
      setAutoLogout(
        auth.expire,
        () => alertWarning("autoLogout", msg.logoutIn5),
        () => clearAlerts(),
      )
    }
  }, [])

  useEffect(() => {
    if (!isAuth) {
      resetAuth()
      loginRedirect()
    } else if (Router.pathname === "/") {
      homeRedirect(user)
    }
  }, [isAuth])

  useEffect(() => {
    if (provider && provider.account) {
      const account = provider.account
      window.BotChat.App({
        bot: {
          id: "feedbot-ipex-portace",
        },
        user: { name: `${account.name} ${account.surname}`.trim() },
        userData: { id: account.providerId, email: account.email },
      })

      return () => {
        const chatbot = document.getElementsByClassName("feedbot-wrapper")[0]
        if (chatbot) {
          chatbot.remove()
        }
      }
    }
  }, [provider])

  const hideNavigation = query.menu === "hideall" || isExported
  return (
    <div className={classes.page}>
      {!hideNavigation && (
        <>
          <HeaderBar
            searchValue={query.searchValue}
            menuCollapsed={collapsed}
            toggleMenu={() => setCollapsed(!collapsed)}
            justSupport={justSupport}
          />
          <SideBar collapsed={collapsed} />
        </>
      )}
      <AlertList sidePanel={!hideNavigation} />
      {children}
    </div>
  )
}
export default Layout

Layout.propTypes = {
  query: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}
