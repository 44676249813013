/** Created by Tomáš Korec on 2020-1-12 */

import React, { useContext } from "react"
import PropTypes from "prop-types"
import { createUseStyles } from "react-jss"

import { logout, operatorLogout, CookieContext, role } from "../../core"
import { useI18n } from "../../internationalization/i18n"

import Link from "../common/Link"
import IconLink from "../common/IconLink"
import Icon from "../common/Icon"

import { useRouter } from "next/router"

const useStyles = createUseStyles(theme => ({
  accountBar: {
    display: "flex",
    alignItems: "center",
    paddingRight: "1rem",
    fontSize: "0.875rem",
  },
  button: {
    padding: "0 0.5rem",
    fontSize: "1.25rem",
  },
  iconLink: {
    extend: "button",
    marginTop: "0.3rem",
    "& span": {
      color: theme.color.white,
    },
  },
  userLabel: {
    padding: "0 0.5rem",
    color: theme.color.white,
    lineHeight: "1rem",
    "@media screen and (max-width: 40rem)": {
      display: "none",
    },
    "& a": {
      color: theme.color.white,
      textDecoration: "none",
      cursor: "pointer",
    },
  },
  userIcon: {
    extend: "button",
    display: "none",
    "@media screen and (max-width: 40rem)": {
      display: "block",
      color: theme.color.white,
    },
  },
  supportLink: {
    extend: "button",
    marginTop: "0.3rem",
    "& a": {
      textDecoration: "none",
      color: theme.color.white,
    },
  },
}))

const getDisplayName = (user, provider, msg) => {
  if (!user || !user.name) return msg.warning.alertNoRoles
  return `${
    user.operator
      ? `${user.name} (operator)${user.hasMask ? ` / ${provider.name}` : ""}`
      : user.name
  } / ${user.login}`
}

const AccountBar = () => {
  const { user, provider } = useContext(CookieContext)
  const messages = useI18n().messages.common

  const classes = useStyles()

  const displayName = getDisplayName(user, provider, messages)

  return (
    <div className={classes.accountBar} data-cy="loginPanel">
      <div className={classes.userLabel}>
        {user && !user.operator && user.roles[role.SUPERADMIN] ? (
          <Link
            href={{ pathname: "accountForm", query: { login: user.login } }}
            key="provider-accountsDetail"
          >
            {displayName}
          </Link>
        ) : (
          <span>{displayName}</span>
        )}
      </div>
      <div className={classes.userIcon}>
        {user && !user.operator && user.roles[role.SUPERADMIN] ? (
          <UserAccountButton login={user.login} displayName={displayName} />
        ) : (
          <UserAccountIcon displayName={displayName} />
        )}
      </div>
      <div className={classes.supportLink}>
        <SupportButton />
      </div>
      {user && user.operator && user.hasMask && (
        <div className={classes.iconLink}>
          <OperatorLogoutButton title={messages.logoutButton.logoutAs} />
        </div>
      )}
      {(!user || !user.hasMask) && (
        <div className={classes.iconLink}>
          <LogoutButton title={messages.logoutButton.loghimout} />
        </div>
      )}
    </div>
  )
}

const UserAccountButton = ({ login, displayName }) => (
  <IconLink to={`/accountForm?login=${login}`} icon="user-cog" title={displayName} />
)

UserAccountButton.propTypes = {
  login: PropTypes.string,
  displayName: PropTypes.string,
}

const UserAccountIcon = ({ displayName }) => <Icon iconType="user" title={displayName} />

UserAccountIcon.propTypes = {
  displayName: PropTypes.string,
}

const LogoutButton = ({ title }) => (
  <IconLink
    to="/login"
    onClick={logout}
    icon="power"
    title={title}
    id="logout"
    data-cy="logout"
    size={20}
  />
)

LogoutButton.propTypes = {
  title: PropTypes.string,
}

const OperatorLogoutButton = ({ title }) => (
  <IconLink
    to="/providers"
    onClick={operatorLogout}
    icon="unlock"
    title={title}
    id="operatorLogout"
    data-cy="operatorLogout"
    size={20}
  />
)

OperatorLogoutButton.propTypes = {
  title: PropTypes.string,
}

const SupportButton = () => {
  const router = useRouter()

  let href = "https://ipexas.atlassian.net/wiki/display/VD/B2Bportal8"
  if (router.pathname === "/phoneNumbers") {
    href = "https://ipexas.atlassian.net/wiki/spaces/VD/pages/1375535143"
  }
  return (
    <a href={href} target="_blank" key="help">
      <Icon iconType="question-circle" data-cy="docs" size={20} />
    </a>
  )
}

export default AccountBar
